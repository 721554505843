// noinspection DuplicatedCode
import {Grid, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {DefaultInputValGetters} from "./Helpers/defaultInputValGetters";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

const ReproductiveHistory = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;
  const {handleFormNavigation} = useFormNavigation();

  const {
    handleOptValInputChange,
    handleRadioChanged,
  } = useReduxDataHandler();

  const {getDefaultTerribleFieldValue} = DefaultInputValGetters();

  const [showAgeFirstPregnancy, setShowAgeFirstPregnancy] = React.useState(false);

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Reproductive History</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5}>
                Moving on to your <b className="text-emphasis">reproductive history</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="menarche">Have you ever had a menstrual period?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={283}
                             name={"menarche"}
                             opp-manifestation={4463}
                             defaultChecked={maniIds.includes(283) || maniIds.includes("283")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4463}
                             name={"menarche"}
                             opp-manifestation={283}
                             defaultChecked={maniIds.includes(4463) || maniIds.includes("4463")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(283) || maniIds.includes("283"))
                      ? <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                        <Grid item xs={6} md={4} ml={{xs: "", sm: 4}}>
                          <label className={"sublabel"}
                                 htmlFor={"ageMenarche"}>At what age was your first period?</label>
                        </Grid>
                        <Grid item>
                          <input
                            aria-labelledby={"ageMenarche"}
                            maxLength={2}
                            type="text"
                            id="menarche"
                            className={"text-input-small"}
                            defaultValue={getDefaultTerribleFieldValue(entities, 283, 26)}
                            placeholder={"Age"}
                            opts-for={283}
                            manioptid={26}
                            inputMode={"numeric"}
                            pattern={"[<= 0-9 +]*"}
                            title={"Numbers only"}
                            onChange={(e) => {
                              if (parseInt(e.target.value) >= 19) {
                                e.target.value = "18 +"
                              }
                              if ((parseInt(e.target.value) > 2) && (parseInt(e.target.value) < 12)) {
                                e.target.value = "<= 11"
                              }
                              handleOptValInputChange(e, false, true, manifestationsState)
                            }} />
                        </Grid>
                      </Grid>
                      : <></>
                  }
                  <Grid container item flexDirection={"column"} justifyContent={"center"} mt={3}>
                    <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                      <Grid item xs={6} md={4}>
                        <label htmlFor="pregnancy">Have you ever been pregnant?</label>
                      </Grid>
                      <Grid item>
                        <input type="radio"
                               value={284}
                               name={"pregnancy"}
                               opp-manifestation={4464}
                               defaultChecked={maniIds.includes(284) || maniIds.includes("284")}
                               onChange={(e) => {
                                 handleRadioChanged(e, usersSelfRelationId)
                               }}
                        /><span>&nbsp;Yes&nbsp;</span>
                        <input type="radio"
                               value={4464}
                               name={"pregnancy"}
                               opp-manifestation={284}
                               defaultChecked={maniIds.includes(4464) || maniIds.includes("4464")}
                               onChange={(e) => {
                                 handleRadioChanged(e, usersSelfRelationId)
                               }}
                        /><span>&nbsp;No&nbsp;</span>
                      </Grid>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(284) || maniIds.includes("284"))
                      ? <>
                        <Grid spacing={2} container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                          <Grid item xs={6} md={4} ml={{xs: "", sm: 4}}>
                            <label className={"sublabel"}
                                   id={"numberPregnancies"}
                                   htmlFor={"numberPregnancies"}>
                              How many times have you given birth?
                            </label>
                          </Grid>
                          <Grid item>
                            <input
                              aria-labelledby={"numberPregnancies"}
                              type={"text"}
                              maxLength={2}
                              className={"text-input-small"}
                              defaultValue={getDefaultTerribleFieldValue(entities, 284, 27)}
                              name={"numberPregnancies"}
                              placeholder={"Number of births"}
                              opts-for={284}
                              manioptid={27}
                              inputMode={"numeric"}
                              pattern={"[< 0-9 +]*"}
                              title={"Numbers only"}
                              onChange={(e) => {
                                if (parseInt(e.target.value) > 20) {
                                  e.target.value = "20 +";
                                }
                                if (parseInt(e.target.value) < 1) {
                                  e.target.value = "0";
                                }
                                if (e.target.value === "0" || e.target.value === "") {
                                  setShowAgeFirstPregnancy(false)
                                } else {
                                  setShowAgeFirstPregnancy(true)
                                }
                                handleOptValInputChange(e, false, true, manifestationsState)
                              }} />
                          </Grid>
                        </Grid>
                        {showAgeFirstPregnancy ? <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                            <Grid item xs={6} md={4} ml={{xs: "", sm: 4}}>
                              <label className={"sublabel"}
                                     id={"ageFirstPregnancy"}
                                     htmlFor={"ageFirstPregnancy"}>
                                How old were you for your first birth?
                              </label>
                            </Grid>
                            <Grid item>
                              <input
                                aria-labelledby={"ageFirstPregnancy"}
                                type={"text"}
                                maxLength={2}
                                className={"text-input-small"}
                                defaultValue={getDefaultTerribleFieldValue(entities, 284, 41)}
                                name={"ageFirstPregnancy"}
                                placeholder={"Age"}
                                opts-for={284}
                                manioptid={41}
                                inputMode={"numeric"}
                                pattern={"[<= 0-9 +]*"}
                                title={"Numbers only"}
                                onChange={(e) => {
                                  if (parseInt(e.target.value) > 45) {
                                    e.target.value = "45 +";
                                  }
                                  handleOptValInputChange(e, false, true, manifestationsState)
                                }} />
                            </Grid>
                          </Grid>
                          : <></>}
                      </>
                      : <></>
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="menopause">
                        Have you started Menopause?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={294}
                             name={"menopause"}
                             opp-manifestation={4465}
                             defaultChecked={maniIds.includes(294) || maniIds.includes("294")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>Yes&nbsp;</span>
                      <input type="radio"
                             value={4465}
                             name={"menopause"}
                             opp-manifestation={294}
                             defaultChecked={maniIds.includes(4465) || maniIds.includes("4465")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)

                             }}
                      /><span>No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(294) || maniIds.includes("294"))
                      ? <>
                        <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                          <Grid item xs={6} md={4} ml={{xs: "", sm: 4}}>
                            <label className={"sublabel"}
                                   id={"ageMenopause"}
                                   htmlFor={"ageMenopause"}>
                              How old were you when you started menopause?
                            </label>
                          </Grid>
                          <Grid item>
                            <input
                              aria-labelledby={"ageMenopause"}
                              maxLength={2}
                              type={"text"}
                              className={"text-input-small"}
                              defaultValue={getDefaultTerribleFieldValue(entities, 294, 42)}
                              placeholder={"Age"}
                              opts-for={294}
                              manioptid={42}
                              inputMode={"numeric"}
                              pattern={"[<= 0-9 +]*"}
                              title={"Numbers only"}
                              onChange={(e) => {
                                if (parseInt(e.target.value) > 60) {
                                  e.target.value = "60 +";
                                }
                                if ((parseInt(e.target.value) < 30) && (parseInt(e.target.value) > 9)) {
                                  e.target.value = "< 30";
                                }
                                handleOptValInputChange(e, false, true, manifestationsState)
                              }} />
                          </Grid>
                        </Grid>
                        <Grid spacing={2} container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                          <Grid item xs={6} md={4}>
                            <label className={"sublabel"}
                                   htmlFor="stillMenopausal">Are you still in menopause? </label>
                          </Grid>
                          <Grid item>
                            <input type="radio"
                                   value={4466}
                                   defaultChecked={(maniIds.includes(4466) || maniIds.includes("4466"))}
                                   name={"stillMenopausal"}
                                   opp-manifestation={4467}
                                   onChange={(e) => {
                                     handleRadioChanged(e, usersSelfRelationId)
                                   }}
                            /><span>&nbsp;Yes&nbsp;</span>
                            <input type="radio"
                                   value={4467}
                                   defaultChecked={(maniIds.includes(4467) || maniIds.includes("4467"))}
                                   name={"stillMenopausal"}
                                   opp-manifestation={4466}
                                   onChange={(e) => {
                                     handleRadioChanged(e, usersSelfRelationId)
                                   }}
                            /><span>&nbsp;No&nbsp;</span>
                          </Grid>
                        </Grid>
                      </>
                      : <></>
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      )
  )
};
export default ReproductiveHistory;