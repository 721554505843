import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {OtherHist} from "./Context/100_Other";
import OtherHistoryRow from "./QuestionnaireComponents/102_OtherHistoryRow";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";

import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useModularReduxData} from "../../Hooks/useModularFormDataHandler";
import useReduxFormData from "../../Hooks/useReduxFormData";
import useReduxModularFormData from "../../Hooks/useReduxModularFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";

import {Button, Grid, Typography} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const OtherHealthHistoryForm = () => {
  useValidateToken(true, "health");
  useReduxFormData();
  const [dispatch, multiManifestationsState, addModularManifestations, , usersGender] = useReduxModularFormData();
  const {
    ids: reactIds,
    entities: modularEntities,
    multiManiFetchLoading,
    multiManiRelationId,
    multiManiDirty,
    multiManiWasFetched,
    multiManiDbPostLoading,
  } = multiManifestationsState;

  const otherHist = useContext(OtherHist);
  const {otherHealth, setOtherHealth, addOtherHist} = otherHist;

  const {
    handleCancerTypeChange: handleOtherTypeChange,
    handleCancerColDataChange: handleOtherColDataChange,
    handlePolypOptValChange,
  } = useModularReduxData();
  const {handleFormNavigation} = useFormNavigation();

  const [scrollOtherHist, setScrollOtherHist] = React.useState(0);

  let arrayOfOthers = [];

  React.useEffect(() => {
    if (reactIds.length > 0) {
      reactIds.forEach((id, index) => {
        if ((modularEntities[id].manifestationLookupCategory !== 1752) || (modularEntities[id].manifestationId === 1427)) {
          return;
        }
        arrayOfOthers.push(modularEntities[id]);
      })
      if (arrayOfOthers.length > 0) {
        setOtherHealth(arrayOfOthers)
      }
    }
  }, [multiManiWasFetched])

  React.useLayoutEffect(() => {
    if (otherHealth.length > 1) {
      document
        .getElementById(`other-${otherHealth.at(-1).reactId}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
    }
  }, [scrollOtherHist]);

  const validateInputs = () => {return true;}

  return (
    (multiManiFetchLoading)
      ? undefined
      : (<>
          <Helmet>
            <title>Other Health</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5} mb={5}>
                Last step for your <b className="text-emphasis">health questions</b>!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      if (validateInputs()) {
                        if (multiManiDirty) {
                          e.preventDefault();
                          await addModularManifestations(Object.values(modularEntities).filter((m) => (m.edited === true)))
                            .unwrap()
                            .then(() => dispatch(fetchManifestations(multiManiRelationId)))
                            .then(() => handleFormNavigation(e))
                        }
                        e.preventDefault();
                        handleFormNavigation(e);
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Typography variant={"subtitle1"} textAlign={"center"} ml={"3rem"} mr={"3rem"}
                              className="extra-label-orange">
                    Tell us about any other conditions you have been diagnosed with; such as autism, cleft lip/palate,
                    cardiovascular anomalies.
                  </Typography>
                  <Grid container item justifyContent={"center"} xs={12} sm={11} md={8}>
                    {
                      ((!multiManiFetchLoading && multiManiWasFetched && otherHealth.length > 0) === true) ? otherHealth.map((row) => {
                          return (
                            modularEntities[row.reactId] !== undefined &&
                            <OtherHistoryRow key={row.reactId}
                                             id={row.reactId}
                                             gender={usersGender}
                                             existingHistory={modularEntities[row.reactId]}
                                             typeChangeHandler={handleOtherTypeChange}
                                             colChangeHandler={handleOtherColDataChange}
                                             polypOptValChangeHandler={handlePolypOptValChange}
                            />)
                        })
                        : undefined
                    }
                  </Grid>
                  <Grid container item xs={12} justifyContent={"center"}>
                    <Button variant={"personal-add"}
                            onClick={(e) => {
                              e.preventDefault();
                              addOtherHist();
                              setScrollOtherHist(scrollOtherHist + 1);
                            }}><AddCircleOutlineIcon />&nbsp;
                      Add a{otherHealth.length > 0 ? "nother" : ""} condition
                    </Button>
                  </Grid>
                  <BackContinueButtons loading={multiManiDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      )
  )
};
