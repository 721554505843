import Grid from "@mui/material/Grid2";

import React from "react";
import {connect} from "react-redux";
import {Link, Typography, useTheme} from "@mui/material";

const Footer = (props) => {
    let {inHealthDiary} = props;
    return (
        <Grid container
              id="footer"
              direction="row"
              style={{display: inHealthDiary ? "none" : "", padding: "10px"}}
        >
            <Grid container size={"auto"} spacing={2} ml={"auto"}>
                <Link variant={"footer-link"} href={"https://dnaimpact.com/pgx/privacy-policies/"}>
                    Privacy Policy
                </Link>
                <Link variant={"footer-link"} href={"https://dnaimpact.com/pgx/patient-privacy/"}>
                    Patient Privacy
                </Link>
                <Link variant={"footer-link"} href={"https://dnaimpact.com/pgx/terms-and-conditions/"}>
                    Terms of Use
                </Link>
                <Link variant={"footer-link"} href={"#"}>
                    Feedback
                </Link>
                <Link variant={"footer-link"} href={"https://dnaimpact.com/pgx/data-security/"}>
                    Data Security
                </Link>
                <Link variant={"footer-link"} href={"https://dnaimpact.com/about-informed-dna/"}>
                    About InformedDNA
                </Link>
            </Grid>
            <Typography variant={"caption"} size={6} ml={5} mr={"auto"}>
                ©2024 Informed Medical Decisions, Inc (dba InformedDNA®) | All rights reserved
            </Typography>
        </Grid>
    );
};

const mapStateToProps = (state) => {
    return {
        inHealthDiary: state.users.inHealthDiary
    }
}

export default connect(mapStateToProps)(Footer);