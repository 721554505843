// noinspection DuplicatedCode
import React, {useContext} from "react";
import {Helmet} from "react-helmet";
import {Biopsy} from "./Context/80_Biopsy";
import BiopsyRow from "./QuestionnaireComponents/82_BiopsyRow";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {
  manifestationsMultiManiDelete
} from "../../Redux/manifestations/multiManifestationSlice";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";

import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useModularReduxData} from "../../Hooks/useModularFormDataHandler";

import useReduxModularFormData from "../../Hooks/useReduxModularFormData";
import {useValidateToken} from "../../Hooks/useValidateToken";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {Button, Grid, Typography} from "@mui/material";

export const BiopsyForm = () => {
  useValidateToken(true, "health");
  const [, , , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: manifestationIds,
    entities: manifestationsEntities,
    manifestationsDbPostLoading,
    manifestationsDirty,
  } = manifestationsState

  const [dispatch, multiManifestationsState, addModularManifestations, , usersGender] = useReduxModularFormData();
  const {
    ids: reactIds,
    entities: modularEntities,
    multiManiFetchLoading,
    multiManiRelationId,
    multiManiDirty,
    multiManiWasFetched,
    multiManiDbPostLoading,
  } = multiManifestationsState;
  const biopsy = useContext(Biopsy);

  const {handleBiopsyTypeChange, handleBiopsyResultChange} = useModularReduxData();
  const {handleRadioChanged} = useReduxDataHandler();
  const {handleFormNavigation} = useFormNavigation();

  const [showBiopsies, setShowBiopsies] = React.useState((manifestationIds.includes(4522) || manifestationIds.includes("4522")));
  const [scrollBiopsy, setScrollBiopsy] = React.useState(0);

  let arrayOfBiopsies = [];

  React.useEffect(() => {
    if (reactIds.length > 0) {
      reactIds.forEach((id) => {
        if (modularEntities[id].manifestationLookupCategory === 1970) {
          arrayOfBiopsies.push(modularEntities[id]);
        }
      })
      biopsy.setBiopsies(arrayOfBiopsies)
      setShowBiopsies(true);
    }
  }, [multiManiWasFetched])

  React.useLayoutEffect(() => {
    if (biopsy.biopsies.length > 1) {
      document
        .getElementById(`biopsy-wrapper-${biopsy.biopsies.at(-1).reactId}`)
        ?.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
    }
  }, [scrollBiopsy]);

  const validateInputs = () => {return true;}

  return (
    (multiManiFetchLoading)
      ? undefined
      : <>
        <Helmet>
          <title>Biopsy History</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" mt={5}>
              You're doing great! {<br />}
              Let's keep talking about your <b className="text-emphasis">health</b>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="questionnaire-content"
                  onSubmit={async (e) => {
                    if (validateInputs()) {
                      if (multiManiDirty) {
                        e.preventDefault();
                        await addModularManifestations(Object.values(modularEntities).filter((m) => (m.edited === true)))
                          .unwrap()
                          .then(() => {
                            if (manifestationsDirty) {
                              addManifestations(Object.values(manifestationsEntities).filter((m) => (m.edited === true))).unwrap();
                            }
                          })
                          .then(() => dispatch(fetchManifestations(multiManiRelationId)))
                          .then(() => handleFormNavigation(e))
                        return;
                      }
                      if (manifestationsDirty) {
                        e.preventDefault();
                        await addManifestations(Object.values(manifestationsEntities).filter((m) => (m.edited === true)))
                          .unwrap()
                          .then(() => {
                            if (multiManiDirty) {
                              addModularManifestations(Object.values(modularEntities).filter((m) => (m.edited === true))).unwrap();
                            }
                          })
                          .then(() => dispatch(fetchManifestations(multiManiRelationId)))
                          .then(() => handleFormNavigation(e))
                        return;
                      }
                      e.preventDefault();
                      handleFormNavigation(e);
                    }
                  }}>
              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                  <Grid item xs={6} md={4}>
                    <label htmlFor="hadBiopsy">Have you ever had any biopsies?</label>
                  </Grid>
                  <Grid item>
                    <input type="radio"
                           value={4522}
                           name={"hadBiopsy"}
                           opp-manifestation={4523}
                           defaultChecked={manifestationIds.includes(4522) || manifestationIds.includes("4522")}
                           onChange={(e) => {
                             handleRadioChanged(e, multiManiRelationId)
                             if (biopsy.biopsies.length === 0) {
                               setShowBiopsies(true);
                             }
                           }}
                    /><span>&nbsp;Yes&nbsp;</span>
                    <input type="radio"
                           value={4523}
                           name={"hadBiopsy"}
                           opp-manifestation={4522}
                           defaultChecked={manifestationIds.includes(4523) || manifestationIds.includes("4523")}
                           onChange={(e) => {
                             handleRadioChanged(e, multiManiRelationId)
                             if (biopsy.biopsies.length > 0) {
                               reactIds.forEach((id) => {dispatch(manifestationsMultiManiDelete(id))});
                             }
                             setShowBiopsies(false);
                           }}
                    /><span>&nbsp;No&nbsp;</span>
                  </Grid>
                </Grid>
                <Grid container item justifyContent={"center"} xs={12} sm={8}>
                  {
                    ((!multiManiFetchLoading && multiManiWasFetched && biopsy.biopsies.length > 0 && showBiopsies) === true)
                      ?
                      biopsy.biopsies.map((row) => {
                        return (
                          modularEntities[row.reactId] !== undefined &&
                          <BiopsyRow key={row.reactId}
                                     id={row.reactId}
                                     gender={usersGender}
                                     existingBiopsy={modularEntities[row.reactId]}
                                     typeChangeHandler={handleBiopsyTypeChange}
                                     resultChangeHandler={handleBiopsyResultChange} />)
                      })
                      : undefined
                  }
                </Grid>
                {
                  (manifestationIds.includes(4522) || manifestationIds.includes("4522")) &&
                  <Grid container item xs={12} justifyContent={"center"}>
                    <Button variant={"personal-add"}
                            onClick={(e) => {
                              e.preventDefault();
                              biopsy.addBiopsy();
                              setScrollBiopsy(scrollBiopsy + 1);
                            }}>
                      <AddCircleOutlineIcon />&nbsp;Add {biopsy.biopsies.length > 0 ? "another" : "a"} biopsy
                    </Button>
                  </Grid>
                }
                <BackContinueButtons loading={multiManiDbPostLoading} />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
  )
}