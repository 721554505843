import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import {
    Box, Fab, Grid,
    InputLabel, ToggleButton, ToggleButtonGroup,
    Typography, useScrollTrigger, Zoom
} from "@mui/material";


import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {filterRelations} from "../../../Redux/relations/relationSlice";
import RenderOwn from "./renderOwn";
import RenderParental from "./renderParental";

function ScrollTop(props) {
    const {children} = props;

    const trigger = useScrollTrigger({
        target: document.querySelector("#questionnaire-wrapper") || undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        document.querySelector("#questionnaire-wrapper").scrollTo({
            top: 0,
            behavior: "smooth",
        })
    };

    return (
        <Zoom in={trigger}>
            <Box
                onClick={handleClick}
                role="presentation"
                sx={{position: 'fixed', bottom: 112, right: 16}}
            >
                {children}
            </Box>
        </Zoom>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
};

const RenderRelations = (props) => {
    const dispatch = useDispatch();
    const {
        selfRelations,
        maternalRelations,
        paternalRelations,
        relationsFilteredBy,
        isMobile,
    } = props;

    const [relationsToShow, setRelationsToShow] = React.useState((relationsFilteredBy !== "") ? relationsFilteredBy : "all");

    return (
        <Grid container item justifyContent={"center"} xs={12} sm={11} md={8}>
            <Grid
                sx={{
                    position: "sticky",
                    top: "-0.1rem",
                    backgroundColor: "white",
                    zIndex: "999",
                    height: "10rem",
                    marginTop: "24px",
                    boxShadow: "-20px 10px 1rem rgba(255,255,255,1.75), 20px 10px 1rem rgba(255,255,255,1.75) ",
                }} elevation={15}
                container justifyContent={"center"}>
                <Grid item xs={12}>
                    <InputLabel>
                        <Typography variant={"h5"} align={"center"} mt={5}>
                            Which relatives would you like to see?
                        </Typography>
                    </InputLabel>
                </Grid>
                <Grid item>
                    <ToggleButtonGroup
                        color="secondary.main"
                        value={relationsToShow}
                        exclusive
                        sx={{
                            "& .Mui-selected": {
                                backgroundColor: "#0279CF !important",
                                color: "#FFFFFF !important",
                            }
                        }}
                        onChange={(e, newRelationsToShow) => {
                            if (newRelationsToShow !== null) {
                                dispatch(filterRelations(newRelationsToShow));
                                setRelationsToShow(newRelationsToShow);
                            }
                        }}
                        aria-label="Which relations to show">
                        <ToggleButton value="all" aria-label="all">
                            All Family
                        </ToggleButton>
                        <ToggleButton value="self" aria-label="my own">
                            My Kids & Full Siblings
                        </ToggleButton>
                        <ToggleButton value="maternal" aria-label="maternal">
                            My Mother's Family
                        </ToggleButton>
                        <ToggleButton value="paternal" aria-label="paternal">
                            My Father's Family
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>
            <Grid item>
                {
                    (relationsToShow === "all" || relationsToShow === "self")
                    && selfRelations
                    && <RenderOwn
                        selfRelations={selfRelations}
                        isMobile={isMobile}/>
                }
            </Grid>
            <Grid item>
                {
                    (relationsToShow === "all" || relationsToShow === "maternal")
                    && maternalRelations
                    && <RenderParental
                        relationsToShow={relationsToShow}
                        parentalRelations={maternalRelations}
                        parentLabel={"mother"}
                        sideLabel={"Maternal"}
                        isMobile={isMobile}/>
                }
            </Grid>
            <Grid item>
                {
                    (relationsToShow === "all" || relationsToShow === "paternal")
                    && paternalRelations
                    && <RenderParental
                        relationsToShow={relationsToShow}
                        parentalRelations={paternalRelations}
                        parentLabel={"father"}
                        sideLabel={"Paternal"}
                        isMobile={isMobile}/>
                }
            </Grid>
            <ScrollTop>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon/>
                </Fab>
            </ScrollTop>
        </Grid>)
};

export default RenderRelations;