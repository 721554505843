// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {Helmet} from "react-helmet";
import {ScreeningBoxMonthYear} from "./QuestionnaireComponents/ScreeningBoxes";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {useValidateToken} from "../../Hooks/useValidateToken";

const MaleCancerScreenings = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;

  const {handleDateColChange, handleRadioChanged} = useReduxDataHandler();
  const {handleFormNavigation} = useFormNavigation();

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Cancer Screenings</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5}>
                Let's finish up with your <b className="text-emphasis">cancer screenings</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadProstateExam">Have you ever had a prostate exam?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={278}
                             name={"hadProstateExam"}
                             opp-manifestation={4496}
                             defaultChecked={maniIds.includes(278) || maniIds.includes("278")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4496}
                             name={"hadProstateExam"}
                             opp-manifestation={278}
                             defaultChecked={maniIds.includes(4496) || maniIds.includes("4496")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(278) || maniIds.includes("278")) &&
                    <ScreeningBoxMonthYear labelText="When was the last visit?"
                                           inputName="hadProstateExam"
                                           manifestationsState={manifestationsState}
                                           maniId={278}
                                           handleInputChange={handleDateColChange} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadPsaTest">
                        Have you ever had a prostate specific antigen (PSA) blood test?
                      </label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={280}
                             name={"hadPsaTest"}
                             opp-manifestation={4497}
                             defaultChecked={maniIds.includes(280) || maniIds.includes("280")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4497}
                             name={"hadPsaTest"}
                             opp-manifestation={280}
                             defaultChecked={maniIds.includes(4497) || maniIds.includes("4497")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(280) || maniIds.includes("280")) &&
                    <ScreeningBoxMonthYear labelText="When was your last doctor exam?"
                                           inputName="hadPsaTest"
                                           manifestationsState={manifestationsState}
                                           maniId={280}
                                           handleInputChange={handleDateColChange} />
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      ))
}

export default MaleCancerScreenings;