import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography} from "@mui/material";
import {KeyboardArrowDown} from "@mui/icons-material";
import {addRelationsToDb, fetchRelations} from "../../../Redux/relations/relationSlice";

const AddRelationButton = (props) => {
  const {label, types, side, parentIds} = props;
  const {usersGender, usersPatientId} = useSelector(state => state.users);
  const {
    relationsDbPosting,
    relationsCardIsExpanded,
    relationsCardExpandedCount
  } = useSelector(state => state.relations);

  const dispatch = useDispatch();

  const handleAddRelation = async (type) => {
    sessionStorage.setItem('relationBuildScrollPosition', document.querySelector("#questionnaire-wrapper").scrollTop)
    let relationId = undefined;
    switch (type) {
      case "Full Sister":
        relationId = 4;
        break;
      case "Full Brother":
        relationId = 5;
        break;
      case "Daughter":
        relationId = 6;
        break;
      case "Son":
        relationId = 7;
        break;
      case "Aunt":
        relationId = 12;
        break;
      case "Uncle":
        relationId = 13;
        break;
      case "Great Aunt":
        relationId = 29;
        break;
      case "Great Uncle":
        relationId = 30;
        break;
      default:
        return undefined;
    }
    const isSelf = (!!parentIds["self"] && (type !== "Full Brother" && type !== "Full Sister"))
    await dispatch(addRelationsToDb([{
      relationId: relationId,
      patientId: usersPatientId,
      side: side ? side : "NA",
      deceased: false,
      motherPrId: isSelf
        ? usersGender === "Female"
          ? parentIds["self"]
          : null
        : parentIds["motherPrId"],
      fatherPrId: isSelf
        ? usersGender === "Male"
          ? parentIds["self"]
          : null
        : parentIds["fatherPrId"],
    }])).then(() => {
      dispatch(fetchRelations());
    })
  }

  React.useEffect(() => {
  }, [])

  return (
    <Grid item my={1} xs={11} lg={8} ml="auto" mr="auto">
      <Accordion disabled={relationsCardIsExpanded} variant={"family-tree"} id={"add-rel-anchor"}>
        <AccordionSummary expandIcon={<KeyboardArrowDown />}>
          <Typography>{relationsCardIsExpanded ? `Save/cancel changes on ${relationsCardExpandedCount} relative${relationsCardExpandedCount > 1 ? "s" : ""} to add a new relative.` : label && label}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent={"space-evenly"}>
            {types.map((type, index) => {
              return (
                <Grid item xs={"auto"} key={index}>
                  <Button
                    variant="outlined"
                    disabled={relationsCardIsExpanded || relationsDbPosting}
                    className={relationsDbPosting ? " button-loading" : ""}
                    onClick={() => {
                      // noinspection JSIgnoredPromiseFromCall
                      handleAddRelation(type);
                    }}>
                    <span className={"backcontinue-button-text"}>
                      {`Add a${(type === "Aunt") || (type === "Uncle") ? "n" : ""} ${type}`}
                    </span>
                  </Button>
                </Grid>
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
}

export default AddRelationButton;