import React from "react";
import AddRelationButton from "./addRelationButton";
import RelationCard from "./relationCard";

import {Grid, Typography} from "@mui/material";

const RenderOwn = (props) => {
    const {selfRelations, isMobile} = props;
    const {
        patientAndRelationsId,
        motherPrId,
        fatherPrId,
    } = selfRelations;

    return (
        <>
            {
                <>
                    <Typography align={"center"} variant={"h2"} color={"black"} mt={5}>Your immediate
                        relatives</Typography>

                    {
                        <Grid item xs={11} lg={8} ml="auto" mr="auto">
                            <Typography
                                variant="h5"
                                align={"left"} mt={5}>
                                {selfRelations.children?.length >= 0 &&
                                    "Add your children"
                                }
                            </Typography>
                        </Grid>
                    }
                    <AddRelationButton
                        label="Add Child"
                        side="NA"
                        types={["Son", "Daughter"]}
                        parentIds={{
                            self: patientAndRelationsId,
                        }}/>
                    {
                        selfRelations.children?.length > 0 &&
                        <>
                            <Typography
                                variant="h5"
                                align={"left"} mt={5}>
                                {selfRelations["children"]?.length >= 1 &&
                                    `Your ${selfRelations["children"]?.length === 1 ? "child" : "children"}`
                                }
                            </Typography>
                            {selfRelations.children?.map((child) => {
                                return (
                                    <RelationCard
                                        key={child.reactId}
                                        reactKey={child.reactId}
                                        entity={child}
                                        multiBirthSelection={selfRelations.children}
                                        isMobile={isMobile}
                                    />
                                )
                            })}
                        </>
                    }
                </>
            }
            {
                <>
                    <Grid item xs={11} lg={8} ml="auto" mr="auto">
                        <Typography
                            variant="h5"
                            align={"left"} mt={5}>
                            {
                                selfRelations["fullSiblings"]?.length >= 0 &&
                                "Add your full siblings"
                            }
                        </Typography>
                    </Grid>
                    <AddRelationButton
                        label="Add Full Sibling"
                        side="NA"
                        types={["Full Brother", "Full Sister"]}
                        parentIds={{
                            motherPrId: motherPrId,
                            fatherPrId: fatherPrId,
                        }}/>
                    {
                        selfRelations["fullSiblings"]?.length > 0 &&
                        <>
                            <Typography
                                variant="h5"
                                align={"left"} mt={5}>
                                {selfRelations["fullSiblings"]?.length >= 1 &&
                                    `Your ${selfRelations["fullSiblings"]?.length === 1 ? "sibling" : "siblings"}`
                                }
                            </Typography>
                            {selfRelations["fullSiblings"]?.map((sibling) => {
                                return (
                                    <RelationCard
                                        key={sibling.reactId}
                                        reactKey={sibling.reactId}
                                        entity={sibling}
                                        multiBirthSelection={[...selfRelations["fullSiblings"], selfRelations]}
                                        isMobile={isMobile}
                                    />
                                )
                            })}
                        </>
                    }
                </>
            }
        </>
    )
}

export default RenderOwn;
