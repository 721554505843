import {createTheme} from "@mui/material";

export const outerTheme = createTheme({
    palette: {
        primary: {
            light: "#FEC633",
            main: "#FFAB00",
            // mainGradient: "linear-gradient(90deg, #1B6481 0%, #23A6BA 100%)",
        },
        secondary: {
            light: "#25A9EF",
            main: "#0279CF",
        },
        error: {
            // light: generated automatically
            main: "#DC0032",
            // dark: generated automatically
        },
        accent: {
            main: "#F27931",
        },
        warning: {
            main: "#FDBB40",
        },
        success: {
            main: "#89C77B",
        },
        info: {
            main: "#F4FAFE",
        },
        inputs: {
            main: "#F27931",
            mainGradient: "linear-gradient(90deg, #F27931 0%, #FC7556 100%) no repeat 0 0",
        },
        // Used by `getContrastText()` to maximize the contrast between
        // the background and the text.
        contrastThreshold: 4.5,
        // Determines how far to shift the 'main' color to get light and dark versions
        // within the color's palette.
        tonalOffset: 0.2,
    },
    typography: {
        // extra quotes look goofy, but if they aren't there, backup fonts fail
        fontFamily: [
            'Quicksand', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', '"Fira Sans"', '"Droid Sans"', '"Helvetica Neue"',
            'sans-serif'
        ].join(','),
    },
    components: {
        MuiButton: {
            variants: [
                // The styles that should win need to be applied last
                // Can also override MUI variants, as seen below
                {
                    props: {variant: "outlined"},
                    style: {
                        background: "white",
                        border: "2px solid #0279CF",
                        color: "#0279CF",
                        textTransform: "none",
                        "&:hover": {
                            background: "white",
                            boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)"
                        },
                    }
                },
                {
                    props: {variant: "undecorated"},
                    style: {
                        all: "unset",
                        cursor: "pointer",
                    }
                },
                {
                    props: {variant: "contained"},
                    style: {
                        background: "#FFAB00",
                        textTransform: "none",
                        color: "black",
                    }
                },
                {
                    props: {variant: "disabled"},
                    style: {
                        background: "#D8EEF2",
                    },
                },
                {
                    props: {variant: "personal-add"},
                    style: {
                        minWidth: "300px",
                        height: "70px",
                        background: "#DDE8EC",
                        borderRadius: "8px",
                        color: "#000",
                        fontSize: "1.25rem",
                        textTransform: "none",
                    },
                },
                {
                    props: {variant: "relation-add"},
                    style: {
                        background: "#DDE8EC",
                        borderRadius: "8px",
                        color: "#000",
                        fontSize: "1rem",
                        textTransform: "none",
                    },
                },
                {
                    props: {variant: "relation-add-relative"},
                    style: {
                        background: "#DDE8EC",
                        borderRadius: "8px",
                        color: "#000",
                        fontSize: "1rem",
                        textTransform: "none",
                        width: "220px",
                    },
                },
            ],
            styleOverrides: {
                root: {
                    color: "white",
                    height: "48px",
                    background: "#c93e00 linear-gradient(90deg, #F27931 0%, #FC7556 100%) no-repeat 0 0",
                    borderRadius: "8px",
                    paddingLeft: "32px",
                    paddingRight: "32px",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                }
            }
        },
        MuiLink: {
            variants: [
                {
                    props: {variant: "dashboard"},
                    style: {
                        color: "#1B6481",
                        cursor: "pointer",
                        '&:hover': {
                            color: "#2DA5B9",
                        },
                    },
                },
                {
                    props: {variant: "footer-link"},
                    style: {
                        color: "white",
                        cursor: "pointer",
                        textDecoration: "none",
                        transitionDuration: ".4s",
                        '&:hover': {
                            color: "black"
                        }
                    }
                }
            ]
        },
        MuiCard: {
            variants: [
                {
                    props: {variant: "family-tree"},
                    style: {
                        background: "#FFFFFF 0 0 no-repeat padding-box",
                        padding: "16px 32px",
                        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: "12px",
                        opacity: 1,
                        height: "fit-content",
                        width: "100%",
                    },
                },
            ],
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#000000",
                    overflow: "visible",
                    whiteSpace: "unset",
                    textOverflow: "unset",
                },
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    background: "#EAEDF0 0 0 no-repeat padding-box",
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    background: "#EAEDF0 0 0 no-repeat padding-box",
                    color: "#1B6481",
                    borderRadius: "12px",
                    '& fieldset': {
                        borderColor: '#DDE8EC',
                    },
                    '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
                        borderColor: '#2DA5B9',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: '#1B6481',
                    },
                    '& fieldset legend': {
                        fontSize: '1rem',
                    },
                },
            }
        },
        MuiAccordion: {
            variants: [
                {
                    props: {variant: "family-tree"},
                    style: {
                        background: "#F0F3FA 0 0 no-repeat padding-box",
                        padding: "16px 32px",
                        borderRadius: "12px",
                        opacity: 1,
                        height: "fit-content",
                        width: "100%",
                        border: "1px solid #E2E8F5",
                        "&:last-of-type": {
                            borderRadius: "12px",
                        },
                        "&:first-of-type": {
                            borderRadius: "12px",
                        },
                    },
                },
            ],
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    marginTop: "20px"
                },
                colorPrimary: {
                    backgroundColor: "#F4FAFE"
                },

            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                }
            },
            variants: [
                {
                    props: {variant: "h2", color: "black"},
                    style: {
                        color: "black",
                        fontWeight: 700,
                        fontSize: "36px",
                    }
                }
            ]
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    border: "1px solid #0279CF",
                },
                firstButton: {
                    borderLeft: "0px",
                    borderRight: "0px"
                },
                lastButton: {
                    marginLeft: "0px",
                    borderLeft: "1px solid #0279CF",
                    borderRight: "0px"
                },
                middleButton: {
                    marginLeft: "0px",
                    borderLeft: "1px solid #0279CF",
                    borderRight: "0px"
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    "&.Mui-selected": {
                        backgroundColor: "#0279CF !important",
                        color: "#FFFFFF !important"
                    },
                    borderLeft: "1px solid #0279CF",
                    borderRight: "1px solid #0279CF",
                    backgroundColor: "#FFFFFF",
                    color: "#0279CF",
                },
            }
        }
    },
    props: {},
    overrides: {},
});