// noinspection JSUnresolvedVariable

import MenuIcon from "@mui/icons-material/Menu";
import {
    AppBar,
    Avatar,
    Box,
    Container,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, {useState} from "react";
import {connect, useDispatch} from "react-redux";
import {Link, NavLink, useNavigate} from "react-router-dom";
import {useUpdateUserMutation} from "../../Redux/api/apiSlice";
import {logoutUser} from "../../Redux/users/userSlice";
import Logo from "../../Resources/Images/DNAA/logo.png";

const AppMenuBar = (props) => {
    const [active, setActive] = useState("home");
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const {inHealthDiary, usersLocation, usersGender, usersObj, token} = props;
    const {activeLink} = usersLocation;
    const userName = usersObj?.userName;
    const [updateUser] = useUpdateUserMutation();

    const activeLinkStyle = {
        textDecoration: "none",
        margin: "0 1rem",
        color: "#0279CF",
        "&:hover": {
            color: "#FFAB00",
            backgroundColor: "unset",
            textDecoration: "none",
            fontSize: "1.0rem"
        },
        "&.active": {
            fontWeight: "bold",
            fontSize: "1.0rem"
        },
        "&.active::before": {
            border: "3px solid #FFAB00",
            content: "''",
            height: 0,
            position: "absolute",
            top: "35px",
            width: "69%",
        },
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem("userToken-v1");
        localStorage.removeItem("patientId");
        dispatch(logoutUser());
    }

    React.useEffect(() => {
        if (inHealthDiary) {
            setActive("basic");
        } else {
            setActive("home");
        }
    }, [inHealthDiary]);

    React.useEffect(() => {
        setActive(activeLink);
    }, [usersLocation]);

    const handleOpenNavMenu = (e) => {
        setAnchorElNav(e.currentTarget);
    }

    const handleOpenUserMenu = (e) => {
        setAnchorElUser(e.currentTarget);
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    }

    const updateUserReturnLater = async () => {
        // redux entity is not extensible, so must define temporary variable
        let usersUpdateObj = {
            ...usersObj, // spread object
            dob: new Date(usersObj.dob + "T00:00:00.000Z"), // add time to date
            token: token
        }
        await updateUser(usersUpdateObj)
    }

    const diaryLinks = [
        {label: "My Basics", path: "/health-questionnaire/basic-info", isActive: "basic"},
        {
            label: "My Health",
            path: "/health-questionnaire/" + (usersGender === "Male" ? "genetics" : "reproductive-history"),
            isActive: "health",
        },
        {label: "My Family", path: "/health-questionnaire/relations-histories", isActive: "history"},
    ]

    const accountLinks = [
        {label: "My Account", path: "/account/settings"},
        {label: "Sign Out", path: "/", onClick: handleLogout}
    ]

    const stringAvatar = (name) => {
        if (!name) {
            return {
                sx: {bgcolor: "primary.main", width: 45, height: 45},
            }
        }
        if ((name.split(" ").length > 1)) {
            return {
                sx: {bgcolor: "primary.main", width: 45, height: 45},
                children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
            }
        }
    }

    return (
        <>
            <AppBar position="fixed" color={"info"} enableColorOnDark>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {
                            inHealthDiary
                                ? (<>
                                    <Typography variant="h5"
                                                noWrap
                                                sx={{
                                                    mr: 2,
                                                    display: {xs: "none", md: "flex"}
                                                }}>
                                        <Link to={"/home"}>
                                            <img src={Logo}
                                                 alt="DNAassist Logo"
                                                 style={{
                                                     height: 50,
                                                 }}/>
                                        </Link>
                                    </Typography>
                                    {/*<Typography variant="h5"*/}
                                    {/*            noWrap*/}
                                    {/*            sx={{display: {xs: "none", md: "flex"}}}>*/}
                                    {/*    <MenuItem*/}
                                    {/*        id={"questionnaire-save-quit"}*/}
                                    {/*        style={{*/}
                                    {/*            color: "#c93e00",*/}
                                    {/*            textDecoration: "none",*/}
                                    {/*            fontWeight: "bold",*/}
                                    {/*            fontSize: "1.25rem"*/}
                                    {/*        }}*/}
                                    {/*        disableRipple*/}
                                    {/*        onClick={(e) => {*/}
                                    {/*            e.preventDefault();*/}
                                    {/*            // noinspection JSIgnoredPromiseFromCall*/}
                                    {/*            updateUserReturnLater();*/}
                                    {/*            navigate("/")*/}
                                    {/*        }}>*/}
                                    {/*        Save and Return Later*/}
                                    {/*    </MenuItem>*/}
                                    {/*</Typography>*/}
                                    <Box sx={{
                                        flexGrow: 1,
                                        justifyContent: "right",
                                        mr: 30,
                                        display: {xs: 'none', md: 'flex'}
                                    }}>
                                        {
                                            diaryLinks.map((link, index) => (
                                                <MenuItem component={NavLink}
                                                          className={active === link.isActive ? "active" : ""}
                                                          disableRipple={true}
                                                          disableTouchRipple={true}
                                                          to={link.path}
                                                          key={index}
                                                          sx={activeLinkStyle}
                                                >
                                                    {link.label}
                                                </MenuItem>))
                                        }
                                    </Box>
                                </>)
                                : (
                                    <Typography variant="h5"
                                                noWrap
                                                sx={{
                                                    mr: 2,
                                                    display: {xs: "none", md: "flex"}
                                                }}>
                                        <Link to={"/home"}>
                                            <img src={Logo}
                                                 alt="Inheret Logo"
                                                 style={{
                                                     height: 50,
                                                 }}/>
                                        </Link>
                                    </Typography>
                                )
                        }
                        {/* Mobile menu */}
                        {
                            // This conditional is only necessary at the moment because there are no links
                            // on the homepage, other than the account settings.
                            // once we have other links, remove outer "inHealthDiary" condition.
                            inHealthDiary && <>
                                <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                                    <IconButton size={"large"}
                                                aria-label={"current user options"}
                                                aria-controls={"menu-appbar"}
                                                aria-haspopup={"true"}
                                                onClick={handleOpenNavMenu}
                                                color={"secondary"}>
                                        <MenuIcon/>
                                    </IconButton>
                                    <Menu id={"menu-appbar"}
                                          anchorEl={anchorElNav}
                                          anchorOrigin={{
                                              vertical: "bottom",
                                              horizontal: "left"
                                          }}
                                          keepMounted
                                          transformOrigin={{
                                              vertical: "top",
                                              horizontal: "left"
                                          }}
                                          open={Boolean(anchorElNav)}
                                          onClose={handleCloseNavMenu}
                                          sx={{display: {xs: "block", md: "none"}}}>
                                        {
                                            inHealthDiary &&
                                            [
                                                diaryLinks.map((link, index) => (
                                                    <MenuItem component={NavLink}
                                                              to={link.path}
                                                              key={index}
                                                              className={active === link.isActive ? "active" : ""}
                                                              onClick={handleCloseNavMenu}>
                                                        <Typography textAlign={"center"}
                                                                    sx={{
                                                                        textDecoration: "none",
                                                                        color: "#1B6481",
                                                                        '&.active': {
                                                                            color: "#c93e00",
                                                                            fontWeight: "bold",
                                                                        }
                                                                    }}>{link.label}</Typography>
                                                    </MenuItem>
                                                )),
                                                <Divider key={"menuDivider"}/>,
                                                <MenuItem
                                                    key={"saveAndReturn"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        handleCloseNavMenu();
                                                        // noinspection JSIgnoredPromiseFromCall
                                                        updateUserReturnLater();
                                                        navigate("/");
                                                    }}>
                                                    <Typography
                                                        id={"questionnaire-save-quit"}
                                                        style={{
                                                            color: "#c93e00",
                                                            textDecoration: "none",
                                                            fontWeight: "bold",
                                                            fontSize: "1.25rem"
                                                        }}>
                                                        Save and Return Later
                                                    </Typography>
                                                </MenuItem>
                                            ]
                                        }
                                    </Menu>
                                </Box>
                            </>
                        }
                        <Typography variant="h5"
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        display: {xs: "flex", md: "none"},
                                        flexGrow: 1,
                                    }}>
                            <Link to={inHealthDiary ? "#" : "/home"} style={inHealthDiary ? {cursor: "default"} : {}}>
                                <img src={Logo}
                                     alt="Inheret Logo"
                                     style={{
                                         height: 50,
                                     }}/>
                            </Link>
                        </Typography>
                        {
                            // Needs to be here to make the user icon stay on the right
                            !inHealthDiary && (
                                <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                </Box>)
                        }
                        {!inHealthDiary && (
                            <Box sx={{flexGrow: 0}}>
                                <Tooltip title={"Open Settings"}>
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar
                                            alt={userName
                                                ? userName
                                                : "avatar"}
                                            {...stringAvatar(userName)} />
                                    </IconButton>
                                </Tooltip>
                                <Menu sx={{mt: '45px', ml: "auto"}}
                                      id="menu-appbar"
                                      anchorEl={anchorElUser}
                                      anchorOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                      }}
                                      keepMounted
                                      transformOrigin={{
                                          vertical: 'top',
                                          horizontal: 'right',
                                      }}
                                      open={Boolean(anchorElUser)}
                                      onClose={handleCloseUserMenu}>
                                    {accountLinks.map((link, index) => (
                                        <MenuItem component={NavLink}
                                                  to={link.path}
                                                  key={index}
                                                  onClick={link.onClick ? link.onClick : handleCloseUserMenu}>
                                            <Typography textAlign={"center"}
                                                        sx={{
                                                            textDecoration: "none",
                                                            color: "#1B6481",
                                                        }}>{link.label}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>)}
                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar style={{position: "fixed", width: "100%", backgroundColor: "#0279CF"}}/>
            <Toolbar/>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        inHealthDiary: state.users.inHealthDiary,
        usersLocation: state.users.usersLocation,
        usersGender: state.users.usersGender,
        token: state.users.usersHasToken,
        usersObj: state.users.entities[state.users.usersPatientId]
    };
}

export default connect(mapStateToProps)(AppMenuBar);