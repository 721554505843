// noinspection DuplicatedCode

import {Grid, Typography} from "@mui/material";
import React from "react";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {Helmet} from "react-helmet";
import {ScreeningBoxMonthYear, ScreeningBoxMonthYearRadio} from "./QuestionnaireComponents/ScreeningBoxes";
import useReduxFormData from "../../Hooks/useReduxFormData";
import {fetchManifestations} from "../../Redux/manifestations/manifestationSlice";
import {useValidateToken} from "../../Hooks/useValidateToken";

const FemaleCancerScreenings = () => {
  useValidateToken(true, "health");
  const [dispatch, usersState, , , manifestationsState, addManifestations] = useReduxFormData();
  const {
    ids: maniIds,
    manifestationsDirty,
    manifestationsDbPostLoading,
    entities,
    manifestationsFetchLoading
  } = manifestationsState;
  const {usersSelfRelationId} = usersState;

  const {handleDateColChange, handleRadioChanged} = useReduxDataHandler();
  const {handleFormNavigation} = useFormNavigation();

  const validateInputs = () => {return true;}

  return (
    manifestationsFetchLoading
      ? undefined
      : (<>
          <Helmet>
            <title>Cancer Screenings</title>
          </Helmet>
          <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
            <Grid item>
              <Typography textAlign={"center"} variant="h2" mt={5}>
                Let's finish up with your <b className="text-emphasis">cancer screenings</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <form className={"questionnaire-content"}
                    onSubmit={async (e) => {
                      e.preventDefault()
                      if (validateInputs()) {
                        if (manifestationsDirty) {
                          await addManifestations(Object.values(entities).filter((m) => m.edited === true))
                            .unwrap()
                            .then(() => {dispatch(fetchManifestations(usersSelfRelationId))})
                            .then(() => handleFormNavigation(e))
                        }
                        handleFormNavigation(e)
                      }
                    }}>
                <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                      className="questionnaire-inner-content">
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadHpvTest">Have you had a HPV (Human Papilloma Virus) test?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4500}
                             opp-manifestation={4501}
                             name={"hadHpvTest"}
                             defaultChecked={maniIds.includes(4500) || maniIds.includes("4500")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4501}
                             opp-manifestation={4500}
                             name={"hadHpvTest"}
                             defaultChecked={maniIds.includes(4501) || maniIds.includes("4501")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4500) || maniIds.includes("4500")) &&
                    <ScreeningBoxMonthYearRadio labelText="When was the last test?"
                                                secondLabelText="Was it positive for HPV?"
                                                inputName="hadHpvTest"
                                                secondInputName="wasHpvTestPositive"
                                                manifestationsState={manifestationsState}
                                                maniId={4500}
                                                secondRadioOpts={[4510, 4511]}
                                                handleInputChange={handleDateColChange}
                                                handleRadioChange={handleRadioChanged}
                                                prId={usersSelfRelationId} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadCa125Test">Have you had a CA-125 blood test?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4504}
                             name={"hadCa125Test"}
                             opp-manifestation={4505}
                             defaultChecked={maniIds.includes(4504) || maniIds.includes("4504")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4505}
                             name={"hadCa125Test"}
                             opp-manifestation={4504}
                             defaultChecked={maniIds.includes(4505) || maniIds.includes("4505")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4504) || maniIds.includes("4504")) &&
                    <ScreeningBoxMonthYearRadio labelText="When was the last test?"
                                                secondLabelText="Was it positive?"
                                                inputName="hadCa125Test"
                                                secondInputName="wasCa125TestPositive"
                                                manifestationsState={manifestationsState}
                                                maniId={4504}
                                                secondRadioOpts={[4512, 4513]}
                                                handleInputChange={handleDateColChange}
                                                handleRadioChange={handleRadioChanged}
                                                prId={usersSelfRelationId} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadPapSmear">Have you had a Pap Smear Test?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4498}
                             name={"hadPapSmear"}
                             opp-manifestation={4499}
                             defaultChecked={maniIds.includes(4498) || maniIds.includes("4498")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4499}
                             name={"hadPapSmear"}
                             opp-manifestation={4498}
                             defaultChecked={maniIds.includes(4499) || maniIds.includes("4499")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4498) || maniIds.includes("4498")) &&
                    <ScreeningBoxMonthYear labelText="When was the last test?"
                                           inputName="hadPapSmear"
                                           manifestationsState={manifestationsState}
                                           maniId={4498}
                                           handleInputChange={handleDateColChange} />
                  }
                  <Grid container item justifyContent={{sm: "center"}} flexDirection={"row"}>
                    <Grid item xs={6} md={4}>
                      <label htmlFor="hadTransVagUltrasound">Have you had a Transvaginal Ultrasound?</label>
                    </Grid>
                    <Grid item>
                      <input type="radio"
                             value={4502}
                             name={"hadTransVagUltrasound"}
                             opp-manifestation={4503}
                             defaultChecked={maniIds.includes(4502) || maniIds.includes("4502")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;Yes&nbsp;</span>
                      <input type="radio"
                             value={4503}
                             name={"hadTransVagUltrasound"}
                             opp-manifestation={4502}
                             defaultChecked={maniIds.includes(4503) || maniIds.includes("4503")}
                             onChange={(e) => {
                               handleRadioChanged(e, usersSelfRelationId)
                             }}
                      /><span>&nbsp;No&nbsp;</span>
                    </Grid>
                  </Grid>
                  {
                    (maniIds.includes(4502) || maniIds.includes("4502")) &&
                    <ScreeningBoxMonthYear labelText="When was your last doctor exam?"
                                           inputName="hadTransVagUltrasound"
                                           maniId={4502}
                                           manifestationsState={manifestationsState}
                                           handleInputChange={handleDateColChange} />
                  }
                  <BackContinueButtons loading={manifestationsDbPostLoading} />
                </Grid>
              </form>
            </Grid>
          </Grid>
        </>
      ))
}

export default FemaleCancerScreenings;