import {Button, Grid, TextField, Typography} from "@mui/material";
import {useFormik} from "formik";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import * as yup from "yup";
import {useUpdateUserMutation} from "../../Redux/api/apiSlice";
import {fetchUserData, updateUserFields} from "../../Redux/users/userSlice";

const PersonalInformation = () => {
  const dispatch = useDispatch();
  const usersState = useSelector(state => state.users);
  const [updateUser, {isLoading: userApiIsLoading}] = useUpdateUserMutation();
  const [updateSuccess, setUpdateSuccess] = React.useState(false);

  const {userFirst, userLast, userEmail, userPhone} = usersState.entities[usersState.ids[0]];
  const {usersHasToken, usersAxiosConfig, usersFetchLoading, usersPatientId} = usersState;
  const navigate = useNavigate();

  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

  const formSchema = yup.object({
    userFirst: yup.string().required("First name is required"),
    userLast: yup.string().required("Last name is required"),
    userEmail: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),

    userPhone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Phone number must be at least 10 digits")
  })

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    values,
    errors,
    onSubmit,
    handleReset,
    resetForm,
  } = useFormik({
    initialValues: {
      userFirst: userFirst ? userFirst : "",
      userLast: userLast ? userLast : "",
      userEmail: userEmail ? userEmail : "",
      userPhone: userPhone ? userPhone : "",
    },
    validationSchema: formSchema,
    onSubmit: async (values) => {
      await dispatch(updateUserFields(values));
      await updateUser({
        ...usersState.entities[usersState.ids[0]],
        token: usersHasToken,
        usersPatientId: usersPatientId,
      })
        .unwrap()
        .then(() => {
          setUpdateSuccess(true);
        })
    }
  });

  React.useEffect(() => {
    dispatch(updateUserFields(values));
    setUpdateSuccess(false);
  }, [touched]);

  return usersFetchLoading ? (
    <div>
      Loading...
    </div>
  ) : (<>
    <Grid item xs={12} mb={5}>
      <Typography variant={"h5"} textAlign={"center"}>
        Your Personal Info
      </Typography>
    </Grid>
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit(e);
    }}>
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <TextField type="text" id="userFirst"
                     name="userFirst" label="First Name"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["userFirst"]}
                     helperText={errors["userFirst"]}
                     value={values.userFirst ? values.userFirst : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto">
          <TextField type="text" id="userLast"
                     name="userLast" label="Last Name"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["userLast"]}
                     helperText={errors["userLast"]}
                     value={values.userLast ? values.userLast : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs="auto">
          <TextField type="text" id="userEmail"
                     name="userEmail" label="Email"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["userEmail"]}
                     helperText={errors["userEmail"]}
                     value={values.userEmail ? values.userEmail : ""}
                     variant="outlined" />
        </Grid>
        <br /><br />
        <Grid item xs="auto">
          <TextField type="text" id="userPhone"
                     name="userPhone" label="Phone Number"
                     onChange={handleChange} onBlur={handleBlur}
                     required aria-required error={!!errors["userPhone"]}
                     helperText={errors["userPhone"]}
                     value={values.userPhone ? values.userPhone : ""}
                     variant="outlined" />
        </Grid>
        <Grid item xs={12}><br /></Grid>
        <Grid item>
          {updateSuccess && <Typography variant={"h5"} sx={{color: "green"}}>
            Successfully updated your personal information.
          </Typography>}
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            className={(userApiIsLoading ? " button-loading" : "")}
            disabled={!Object.keys(touched).length > 0}
            type="submit">
                <span className="backcontinue-button-text"
                      id="next-questionnaire-text">
                  Save Changes
                </span>
          </Button>
          &nbsp;&nbsp;
          <Button
            variant="outlined"
            sx={{display: `${userApiIsLoading ? "none" : ""}`}}
            disabled={!Object.keys(touched).length > 0}
            onClick={(e) => {
              e.preventDefault();
              handleReset(e);
            }}>
            Reset Changes
          </Button>
        </Grid>
      </Grid>
    </form>
  </>);
}

export default PersonalInformation;