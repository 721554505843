// noinspection JSUnresolvedVariable

import {Visibility, VisibilityOff} from "@mui/icons-material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import React, {useState} from "react";
import {Helmet} from "react-helmet";

import "../Styles/Login.css"
import {Link} from "react-router-dom";
import LandingPageFamilyPhotoGrid from "../Components/LandingPageFamilyPhotoGrid";
import PasswordProgressBar from "../Components/PasswordProgressBar";
import {scorePassword, validatePassReqs, validatePasswordComplexity} from "../Helpers/passwordComplexity";
import store from "../Redux/store/store";
import {fetchUserData, loginUser, registerNewPatient} from "../Redux/users/userSlice";
import {useDispatch} from "react-redux";

import {Button, Grid, IconButton, Input, InputAdornment, useMediaQuery, useTheme} from "@mui/material";

const Signup = (props) => {
  const theme = useTheme();
  const {buttonLoad} = props;
  const [inputs, setInputs] = useState({});
  const [errors, setErrors] = useState({});
  const [passReqs, setPassReqs] = useState({});
  const [badErrors, setBadErrors] = useState({});
  const [passScore, setPassScore] = useState({text: "Weak", score: 0, className: "progress-bar-danger"});
  const [showPass, setShowPass] = useState(false);

  const dispatch = useDispatch();
  const hideImageText = useMediaQuery(theme.breakpoints.down("md"));

  const handleSignup = async (e) => {
    //TODO: move to redux
    e.preventDefault();
    let validInput = validateInputs();
    let {meetsAll} = validatePassReqs(inputs["userPwd"]);
    if (!meetsAll) {
      return;
    }
    if (validInput && meetsAll) {
      //TODO: error handling for bad input
      let requestStatus =
        await dispatch(registerNewPatient(inputs));
      let validOtpDobAndName = !!requestStatus.payload;
      if (!validOtpDobAndName) {
        setBadErrors({
          ...badErrors,
          "message": "There was a problem validating your One-Time Passcode, Date of Birth, or Last Name. Please double check your entries and try again."
        });
      }
      if (validOtpDobAndName) {
        const credentials = {
          email: inputs["email"],
          password: inputs["userPwd"],
        };
        await dispatch(loginUser(credentials))
          .then(() => {
            store.dispatch(fetchUserData(store.getState().users.patientId));
          })
      }
    }
  }

  const handleChange = (e) => {
    setErrors({...errors, [e.target.name]: ""});
    setBadErrors({});
    setInputs({...inputs, [e.target.name]: e.target.value});
  }

  const handlePassChange = (e) => {
    setErrors({...errors, [e.target.name]: ""});
    setBadErrors({});
    let passStrength = validatePasswordComplexity(e.target.value);
    const {requirements} = validatePassReqs(e.target.value);
    setPassReqs(requirements);
    let status = scorePassword(passStrength);
    setPassScore(status);
    setInputs({...inputs, [e.target.name]: e.target.value});
  }

  const handleShowPass = (e) => {
    e.preventDefault();
    setShowPass(!showPass);
  }

  const validateInputs = () => {
    let isValid = true;
    let errors = {};

    if (!inputs["otp"]) {
      isValid = false;
      errors["otp"] = "Please enter your OTP";
    }

    if (!inputs["dob"]) {
      isValid = false;
      errors["dob"] = "Please enter your date of birth";
    }

    if (!inputs["lastName"]) {
      isValid = false;
      errors["lastName"] = "Please enter your last name";
    }

    if (!inputs["email"]) {
      isValid = false;
      errors["email"] = "Please enter an email address";
    }

    if (!inputs["userPwd"]) {
      isValid = false;
      errors["userPwd"] = "Please enter a password";
    }

    if (!inputs["confirmPass"]) {
      isValid = false;
      errors["confirmPass"] = "Please confirm your password";
    }

    if (typeof inputs["userPwd"] !== "undefined" && typeof inputs["confirmPass"] !== "undefined") {
      if (inputs["userPwd"] !== inputs["confirmPass"]) {
        isValid = false;
        errors["confirmPass"] = "Passwords do not match";
      }
    }

    setErrors(errors);
    return isValid;
  }

  return (
    <>
      <Helmet>
        <title>Welcome to DNAassist</title>
      </Helmet>
      <Grid container mt={5}>
        <LandingPageFamilyPhotoGrid />
        <Grid item xs={12} sm={12} md={6} className="loginForm"
              sx={hideImageText ? {borderRadius: "15px"} : {}}>
          <img className="img-logo"
               src={require("../Resources/Images/DNAA/logo.png")}
               alt="DNAassist Logo" />
          <div className="signup-info">
            Complete this form to verify your account. Please enter the last name and date of birth that are on record
            with your healthcare provider.
          </div>
          <form onSubmit={(e) => { // noinspection JSIgnoredPromiseFromCall
            handleSignup(e)
          }}>
            <br />
            <h4 className="">Register an account</h4><br /><br />
            <Grid container rowSpacing={1} columnSpacing={1}>
              <Grid item xs={12} sm={6}>
                <label style={{minWidth: "182px"}}> One-Time Passcode </label><br />
                <Input name="otp"
                       type="text"
                       placeholder="OTP"
                       className="text-input"
                       disableUnderline
                       onChange={handleChange} />
                {errors["otp"] && <div className="error-text">{errors["otp"]}</div>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label> Date Of Birth </label><br />
                <Input name="dob"
                       type="date"
                       className="text-input"
                       disableUnderline
                       onChange={handleChange} />
                {errors["dob"] && <div className="error-text">{errors["dob"]}</div>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className=""> Last Name </label>
                <br />
                <Input name="lastName"
                       type="text"
                       placeholder="Last Name"
                       className="text-input"
                       disableUnderline
                       onChange={handleChange} />
                {errors["lastName"] && <div className="error-text">{errors["lastName"]}</div>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className=""> Email Address </label>
                <br />
                <Input name="email"
                       type="text"
                       placeholder="Email Address"
                       className="text-input"
                       disableUnderline
                       onChange={handleChange} />
                {errors["email"] && <div className="error-text">{errors["email"]}</div>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className="">
                  Password </label>
                <br />
                {/*TODO: add show/hide eyeball*/}
                <Input name="userPwd"
                       type={`${showPass ? "text" : "password"}`}
                       id="userPwd"
                       placeholder="Password"
                       className="text-input"
                       onChange={handlePassChange}
                       disableUnderline={true}
                       endAdornment={
                         <InputAdornment position={"end"}>
                           <IconButton onClick={handleShowPass}>
                             {showPass ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       }
                />
                {errors["userPwd"] && <div className="error-text">{errors["pass"]}</div>}
              </Grid>
              <Grid item xs={12} sm={6}>
                <label className="">
                  Confirm Password </label>
                <br />
                <Input name="confirmPass"
                       type={`${showPass ? "text" : "password"}`}
                       placeholder="Confirm Password"
                       className="text-input"
                       onChange={handleChange}
                       disableUnderline={true}
                       endAdornment={
                         <InputAdornment position={"end"}>
                           <IconButton onClick={handleShowPass}>
                             {showPass ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       }
                /> {errors["confirmPass"] && <div className="error-text">{errors["confirmPass"]}</div>}
              </Grid>
              {(Object.entries(passReqs).length !== 0) && (
                <Grid item xs={12}>
                  {
                    passScore.score > 0 &&
                    <Grid item xs={6}>
                      <PasswordProgressBar passScore={passScore} />
                    </Grid>
                  }
                  <div className="error-text underline">Your password must contain:</div>
                  {passReqs.hasLowercase
                    ? <div className="pass-req">&#10003; A lowercase character</div>
                    : <div className="pass-req-not">A lowercase character</div>}
                  {passReqs.hasUppercase
                    ? <div className="pass-req">&#10003; An uppercase character</div>
                    : <div className="pass-req-not">An uppercase character</div>}
                  {passReqs.hasNumber
                    ? <div className="pass-req">&#10003; A number</div>
                    : <div className="pass-req-not">A number</div>}
                  {passReqs.hasSpecial
                    ? <div className="pass-req">&#10003; A special character ~ ` ! ? @ # $ % ^ & * , - _</div>
                    : <div className="pass-req-not">A special character ~ ` ! ? @ # $ % ^ & * , - _</div>}
                  {passReqs.hasLength
                    ? <div className="pass-req">&#10003; At least 8 characters</div>
                    : <div className="pass-req-not">At least 8 characters</div>}
                  <div className="error-text underline">Your password cannot contain:</div>
                  {passReqs.hasIllegal
                    ? <div className="pass-req-not">Illegal characters: {`/ \\ ( ) < > [ ]`} </div>
                    : <div className="pass-req"> &#10003; Illegal characters: {`/ \\ ( ) < > [ ]`}</div>}
                </Grid>)}
            </Grid>
            <br />
            <Grid container direction={"column"}>
              <Grid item xs={2} sx={{marginLeft: "auto"}}>
                <Button type="submit"
                        variant="contained"
                        name="next"
                        style={{marginRight: "50px", marginLeft: "auto"}}
                        className={(buttonLoad ? " button-loading" : "")}>
                  <span className="backcontinue-button-text">Register</span>
                  <ArrowForwardIcon className="backcontinue-button-text" sx={{ml: 1}} />
                </Button>
              </Grid>
              <br /><br />
              <Grid item xs={2} justifySelf={"center"}
                    style={{margin: "0 auto"}}>
                Have an account?&nbsp;<Link to="/">Log in here</Link>
              </Grid>
            </Grid>
            {badErrors &&
              <Grid item xs={"auto"} className="bad-error error-text center-text attention">{badErrors.message}</Grid>}
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default Signup;
