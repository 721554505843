import {Card, CardMedia, Grid, Typography} from "@mui/material";
import {useValidateToken} from "../../Hooks/useValidateToken";
import Diary from "../../Resources/Images/NewFoundry Graphics/assessment-main-graphic.svg";
import React from "react";
import {Helmet} from "react-helmet";
import useExitPrompt from "../../Hooks/useExitPrompt";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useNavigate} from "react-router-dom";
import {useCurrentPosition} from "./Helpers/stepOrder";
import useReduxFormData from "../../Hooks/useReduxFormData";

const FirstPageNewPatient = () => {
  useValidateToken(true, "basic");
  useExitPrompt(true);
  const navigate = useNavigate();
  const {next} = useCurrentPosition();
  const [, usersState] = useReduxFormData();

  const {usersPatientId, usersFetchLoading: fetchUserLoading, entities} = usersState;

  const handleNext = (e) => {
    e.preventDefault();
    navigate(`/health-questionnaire/${next.path}`);
  }


  let firstName = undefined;

  if (entities[usersPatientId] && entities[usersPatientId].userFirst) {
    firstName = entities[usersPatientId].userFirst;
  }

  return (
    fetchUserLoading
      ? <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
        Loading...</Grid>
      : (<>
        <Helmet>
          <title>Welcome</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item xs={8}>
            <Card sx={{backgroundColor: "transparent", boxShadow: "unset", borderRadius: "unset"}}>
              <CardMedia component="img"
                         image={Diary}
                         alt="health diary"
                         title={"health-diary"}
                         sx={{
                           maxWidth: {xs: "250px", sm: "500px"},
                           maxHeight: {xs: "350px"},
                           mt: 5,
                           ml: "auto",
                           mr: "auto",
                         }} />
            </Card>
            <Typography textAlign={"center"} variant={"h2"}>Welcome{firstName ? `, ${firstName}` : ""}! Today is a great
              day to start
              your <b className="text-emphasis">health
                diary.</b>
            </Typography>
            <form className="questionnaire-content"
                  onSubmit={handleNext}>
              <BackContinueButtons />
            </form>
          </Grid>
        </Grid>
      </>)
  )
    ;
}

export default FirstPageNewPatient;