import {Button, Grid, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useRelations} from "../../Hooks/useRelations";
import {useValidateToken} from "../../Hooks/useValidateToken";
import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";

const ResultsPage = () => {
  const navigate = useNavigate();
  useValidateToken(true, "history");
  const {handleFormNavigation} = useFormNavigation();

  const {relationsState, relationsIsLoading} = useRelations();
  const {ids} = relationsState;

  const needsToBuildTree = ids.length < 15;

  return (
    !relationsIsLoading ? <>
        <Helmet>
          <title>Personal History Complete</title>
        </Helmet>
        <Grid container item xs={12} justifyContent={"center"} className="questionnaire-wrapper">
          <Grid item>
            <Typography textAlign={"center"} variant="h2" m={{xs: 5, md: 15}}>
              Now it's time to {needsToBuildTree ? "build" : "finish"} your <b className="text-emphasis">family tree</b>,
              and complete your risk
              assessment. </Typography>
          </Grid>
          <Grid item xs={12}>
            <form className="questionnaire-content"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleFormNavigation(e);
                  }}>
              <Grid spacing={2} container justifyContent={"center"} ml={"auto"} mr={"auto"}
                    className="questionnaire-inner-content">
                {
                  needsToBuildTree
                    ? (<Button variant={"contained"}
                               name="next"
                               type="submit">
                      Build Family Tree
                    </Button>)
                    : (<Button variant={"contained"}
                               name="next"
                               onClick={(e) => {
                                 e.preventDefault();
                                 navigate("/health-questionnaire/relations-histories")
                               }}
                    >
                      Finish Family History
                    </Button>)
                }
                <BackContinueButtons />
              </Grid>
            </form>
          </Grid>
        </Grid>
      </>
      : <></>
  );
}

export default ResultsPage;