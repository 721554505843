import {FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {Helmet} from "react-helmet";
import React from "react";
import {useValidateToken} from "../../Hooks/useValidateToken";

import BackContinueButtons from "./QuestionnaireComponents/BackContinueButtons";
import {useFormNavigation} from "../../Hooks/useFormNavigation";
import {useReduxDataHandler} from "../../Hooks/useFormDataHandler";
import {keepUserGenderUpdated, updateUserState} from "../../Redux/users/userSlice";
import useReduxFormData from "../../Hooks/useReduxFormData";

// applies left and right margin to form input elements
const FORM_MARGIN = {xs: 2, s: 20};

// max width of each individual input element
const ELEMENT_WIDTH = "400px";

const BasicInfo = () => {
    useValidateToken(true, "basic");
    //TODO - refactor this to use a redux type data handler, and not local state
    const [dispatch, usersState, updateUser, userApiIsLoading] = useReduxFormData();
    const [loadNum, setLoadNum] = React.useState(0);

    const {
        entities,
        usersPatientId,
        usersIsDirty: dirty,
        usersFetchLoading: fetchUserLoading,
        usersHasToken
    } = usersState;
    const {handleFormNavigation} = useFormNavigation();

    const getInitialFormState = (id) => {
        let {
            userFirst,
            userLast,
            dob,
            patientRace,
            gender,
            height,
            heightFt,
            heightIn,
            weight,
            genderIdentity,
            preferredPronouns,
        } = entities[id];

        return {
            userFirst: userFirst ? userFirst : "",
            userLast: userLast ? userLast : "",
            dob: dob ? dob : null,
            patientRace: patientRace ? patientRace : null,
            gender: gender ? gender : null,
            genderIdentity: genderIdentity ? genderIdentity : null,
            preferredPronouns: preferredPronouns ? preferredPronouns : null,
            height: height ? height : 0,
            weight: weight ? weight : 0,
            heightFt: heightFt ? heightFt : 0,
            heightIn: heightIn ? heightIn : 0,
        }
    }

    const {inputs, errors, setErrors, handleBasicInputChange} =
        useReduxDataHandler(getInitialFormState(usersPatientId));

    React.useEffect(() => {
        setLoadNum(loadNum + 1);
        dispatch(updateUserState(inputs))
    }, [inputs])

    const validateInputs = () => {
        let isValid = true;
        let errors = {};

        if (!inputs["userFirst"]) {
            isValid = false;
            errors["userFirst"] = "This is a required field.";
        }

        if (!inputs["userLast"]) {
            isValid = false;
            errors["userLast"] = "This is a required field.";
        }

        if (!inputs["dob"]) {
            isValid = false;
            errors["dob"] = "This is a required field.";
        }

        if (!inputs["gender"]) {
            isValid = false;
            errors["gender"] = "This is a required field."
        }

        setErrors(errors);
        return isValid;
    }

    return (
        fetchUserLoading
            ? undefined
            : (<>
                <Helmet>
                    <title>Basic Information</title>
                </Helmet>
                <Grid container xs={12} justifyContent={"center"} className="questionnaire-wrapper">
                    <Grid item size={12}>
                        <Typography align={"center"} variant={"h2"} mt={5}>Let's start with <b
                            className="text-emphasis">the basics</b></Typography>
                    </Grid>
                    <form className="questionnaire-content"
                          onSubmit={async (e) => {
                              e.preventDefault();
                              if (validateInputs()) {
                                  if (dirty && loadNum > 1) {
                                      let dob = inputs["dob"];
                                      let userMod = {
                                          ...inputs,
                                          dob: new Date(dob + "T00:00:00.000Z"),
                                          token: usersHasToken,
                                          usersPatientId: usersPatientId
                                      };
                                      await updateUser(userMod)
                                          .unwrap()
                                          .then(() => handleFormNavigation(e))
                                  }
                                  handleFormNavigation(e)
                              }
                          }}>
                        <Grid spacing={4} container justifyContent={"center"} size={12}>
                            <Grid container justifyContent={"center"} size={12} ml={FORM_MARGIN}
                                  mr={FORM_MARGIN}>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>
                                    <InputLabel id={"userFirst"}>First Name *</InputLabel>
                                    <TextField aria-labelledby={"userFirst"}
                                               error={!!errors["userFirst"]}
                                               helperText={errors["userFirst"]}
                                               id={"userFirst"}
                                               name={"userFirst"}
                                               type="text"
                                               value={(inputs.userFirst && inputs.userFirst) || ""}
                                               onChange={handleBasicInputChange}/>
                                </Grid>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>
                                    <InputLabel id={"userLast"}>Last Name *</InputLabel>
                                    <TextField aria-labelledby={"userLast"}
                                               error={!!errors["userLast"]}
                                               helperText={errors["userLast"]}
                                               id={"userLast"}
                                               name={"userLast"}
                                               type="text"
                                               value={(inputs.userLast && inputs.userLast) || ""}
                                               onChange={handleBasicInputChange}/>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"} size={12} ml={FORM_MARGIN}
                                  mr={FORM_MARGIN}>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>

                                    <InputLabel id="dob">Date of Birth *</InputLabel>
                                    <input type="date"
                                           name="dob"
                                           className={errors["dob"] ? "text-input text-input-figma error" : "text-input text-input-figma"}
                                           defaultValue={(inputs.dob && inputs.dob) || ""}
                                           onChange={handleBasicInputChange}/>
                                </Grid>
                                <Grid container display={"flex"} spacing={1} maxWidth={ELEMENT_WIDTH}
                                      flexDirection={"column"} size={6}>
                                    <InputLabel id={"gender"}>
                                        Sex at Birth *
                                    </InputLabel>
                                    <RadioGroup row aria-labelledby={"gender"} name={"gender"}>
                                        <FormControlLabel value="Female"
                                                          control={<Radio color={"secondary"}/>}
                                                          label="Female"
                                                          name="gender"
                                                          checked={(inputs.gender && inputs.gender === "Female") || null}
                                                          onChange={(e) => {
                                                              dispatch(keepUserGenderUpdated(e.target.value));
                                                              handleBasicInputChange(e);
                                                          }}/>
                                        <FormControlLabel value="Male"
                                                          control={<Radio color={"secondary"}/>}
                                                          label="Male"
                                                          name="gender"
                                                          checked={(inputs.gender && inputs.gender === "Male") || null}
                                                          onChange={(e) => {
                                                              dispatch(keepUserGenderUpdated(e.target.value));
                                                              handleBasicInputChange(e);
                                                          }}/>
                                    </RadioGroup>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"} size={12} ml={FORM_MARGIN}
                                  mr={FORM_MARGIN}>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>
                                    <InputLabel id="genderIdentity">Gender Identity</InputLabel>
                                    <Select className="dropdown-input"
                                            labelId="genderIdentity"
                                            name="genderIdentity"
                                            value={(inputs.genderIdentity && inputs.genderIdentity) || ""}
                                            onChange={handleBasicInputChange}>
                                        <MenuItem value="">Please select</MenuItem>
                                        <MenuItem value="Female">Female</MenuItem>
                                        <MenuItem value="Male">Male</MenuItem>
                                        <MenuItem value="Non-Binary">Non-binary</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                        <MenuItem value="Non-Conforming">Gender Queer / Non-Conforming</MenuItem>
                                        <MenuItem value="Decline">Prefer not to say</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>
                                    <InputLabel id="patientRace">Race</InputLabel>
                                    <Select className="dropdown-input"
                                            labelId="patientRace"
                                            name="patientRace"
                                            value={(inputs.patientRace && inputs.patientRace) || ""}
                                            onChange={handleBasicInputChange}>
                                        <MenuItem value="">Please select</MenuItem>
                                        <MenuItem value="White">White</MenuItem>
                                        <MenuItem value="African American">African-American</MenuItem>
                                        <MenuItem value="Chinese American">Chinese-American</MenuItem>
                                        <MenuItem value="Filipino American">Filipino-American</MenuItem>
                                        <MenuItem value="Japanese American">Japanese-American</MenuItem>
                                        <MenuItem value="Hawaiian American">Hawaiian-American</MenuItem>
                                        <MenuItem value="Hispanic American (US born)">Hispanic-American (US born)</MenuItem>
                                        <MenuItem value="Hispanic American (Foreign born)">Hispanic-American (Foreign
                                            born)
                                        </MenuItem>
                                        <MenuItem value="Other Pacific Islander">Other Pacific Islander</MenuItem>
                                        <MenuItem value="Other Asian">Other Asian</MenuItem>
                                        <MenuItem value="Other/Unknown">Other/Unknown</MenuItem>
                                        <MenuItem value="Decline">Decline to answer</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                            <Grid container justifyContent={"center"} size={12} ml={FORM_MARGIN}
                                  mr={FORM_MARGIN}>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>
                                    <InputLabel id="preferredPronouns">Preferred Pronouns</InputLabel>
                                    <Select className="dropdown-input"
                                            name="preferredPronouns"
                                            defaultValue={(inputs.preferredPronouns && inputs.preferredPronouns) || undefined}
                                            onChange={handleBasicInputChange}>
                                        <MenuItem value="">Please select</MenuItem>
                                        <MenuItem value="She/Her/Hers">She / Her / Hers</MenuItem>
                                        <MenuItem value="He/Him/His">He / Him / His</MenuItem>
                                        <MenuItem value="They/Them/Theirs">They / Them / Theirs</MenuItem>
                                        <MenuItem value="Other">Other</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid display={"flex"} maxWidth={ELEMENT_WIDTH} flexDirection={"column"} size={6}>
                                </Grid>
                            </Grid>
                            <BackContinueButtons loading={userApiIsLoading}/>
                        </Grid>
                    </form>
                </Grid>
            </>)
    )
}

export default BasicInfo;