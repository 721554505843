// noinspection JSUnresolvedVariable

import React from 'react';
import {Helmet} from 'react-helmet';

import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import LandingPageFamilyPhotoGrid from "../Components/LandingPageFamilyPhotoGrid";
import {submitPwdReset} from "../Redux/users/userSlice";

import {Button, Grid, useMediaQuery, useTheme} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "../Styles/Login.css";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const hideImageText = useMediaQuery(theme.breakpoints.down("md"));

  const [successMessage, setSuccessMessage] = React.useState("");
  const [errorMessage, setErrorMessage] = React.useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (successMessage) {
      navigate("/");
      return;
    }

    const {email, lastName, dob} = e.target;
    const credentials = {email: email.value, lastName: lastName.value, dob: dob.value};

    let result = await dispatch(submitPwdReset(credentials));
    if (!result.payload) {
      setErrorMessage("The email, last name, and date of birth you entered do not match our records. Please check your inputs and try again.");
      return;
    }

    if (result.payload.resetRequestSent) {
      setSuccessMessage(`An email has been sent to ${email.value} with a link to reset your password.`);
      document.getElementById("email").value = "";
      document.getElementById("lastName").value = "";
      document.getElementById("dob").value = "";
    }
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password</title>
      </Helmet>
      <Grid container mt={5}>
        <LandingPageFamilyPhotoGrid />
        <Grid item xs={12} sm={12} md={6} className="loginForm"
              sx={hideImageText ? {borderRadius: "15px", zIndex: 5} : {zIndex: 5}}>
          <img className="img-logo"
               src={require("../Resources/Images/DNAA/logo.png")}
               alt="DNAassist Logo" /><br />
          <Grid mt={5}>
            <h4>Forgot Password</h4>
            <form onSubmit={(e) => handleSubmit(e)}>
              <label className="margintop20px">
                Email Address
              </label>
              <input name="email"
                     id={"email"}
                     type="email"
                     placeholder="Email"
                     className="text-input-wide"
                     onChange={() => {
                       setSuccessMessage("")
                       setErrorMessage("")
                     }} />
              <br /><br />
              <label>Last Name</label>
              <input name="lastName"
                     id={"lastName"}
                     type="text"
                     placeholder="Last Name"
                     className="text-input-wide"
                     onChange={() => {
                       setSuccessMessage("")
                       setErrorMessage("")
                     }} /> <br /><br />
              <label>Date of Birth</label>
              <input name="dob"
                     id={"dob"}
                     type="date"
                     placeholder="Date of Birth"
                     className="text-input-wide"
                     onChange={() => {
                       setSuccessMessage("")
                       setErrorMessage("")
                     }} /> <br /><br />
              {successMessage && <div className="success-text">{successMessage}</div>}
              {errorMessage && <div className="error-text">{errorMessage}</div>}
              <br />
              <Button variant="contained"
                      type={"submit"}
                      className="main-button">
                {successMessage
                  ? (<>
                    <span>Return to Login</span>
                    <ArrowForwardIcon sx={{ml: 1}} />
                  </>)
                  : (<>
                    <span>Submit Reset Request</span>
                    <ArrowForwardIcon className="backcontinue-button-text" sx={{ml: 1}} />
                  </>)
                }
              </Button>
              <br />
              <Button variant="outlined"
                      sx={{mt: 5}}
                      onClick={() => navigate("/")}
                      className="main-button">
                <span>Cancel</span>
              </Button>
            </form>
          </Grid>
        </Grid>
      </Grid>
    </>)
};

export default ForgotPassword;